$theme-color: #271A62;

.fixed-top-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    animation: scroll-top 0.5s linear;

    @keyframes scroll-top {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }
}

.main-header {
    >.nav-link {
        color: #000;
        padding: 30px 15px !important;
        font-size: 14px !important;
        font-weight: 600;
    }

    .header-icon svg {
        width: 25px;
        height: 25px;
    }

    @media (max-width:991px) {
        .head_navbar {
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);

            .nav-link {
                padding: 15px 10px !important;
            }
        }
    }

    .navbar-nav {
        >.nav-item:last-child {
            display: none;
        }
    }
}

.top-header {
    background-color: #271A62;

    .nav-link {
        color: #fff;
        font-size: 14px !important;
        font-weight: 600;

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    .top_icon {
        width: 22px;
        height: 22px;
        color: #fff;
        cursor: pointer;
    }

    .announce {
        .box {
            opacity: 0;
            visibility: hidden;
            transition: .5s;
        }

        &:hover {
            .box {
                opacity: 1;
                visibility: visible;
            }
        }
    }

}

.top_icon {
    cursor: pointer;
}

.top_icon.hidden {
    display: none;
}

.navbar-toggler,
.btn-close {
    &:focus {
        box-shadow: none !important;
    }
}

.navbar-nav {
    .nav-item {
        .nav-link {
            transition: .3s;
        }

        &:hover {
            >.nav-link {
                color: #271A62 !important;
            }
        }
    }
}

.head_navbar {

    .submenu {
        position: absolute;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 12;
        top: 100%;
        left: 0;
        border-radius: 10px;
        overflow: hidden;
        // padding: 10px;
        width: 260px;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;

        li {
            padding: 10px !important;

            &:hover {
                // background-color: #E9EBF8;

                a {
                    border-color: #271A62;
                }
            }

            svg {
                transition: 0.3s;
            }

            &:hover {
                svg {
                    transform: scale(1.2);
                }
            }

            a {
                padding: 0px !important;
                transition: .5s all;
                margin-left: 10px !important;
                display: inline-flex;
                align-items: center;
                border-bottom: 1px solid transparent;
            }
        }
    }

    .submenu.active {
        opacity: 1;
        visibility: visible;

    }

    .nav-item:hover .submenu {
        opacity: 1;
        visibility: visible;
    }
}

.submenu1 {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(110%);
}

.submenu1.actives {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateX(0);
}

.mega_div {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.4);
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    ul {
        width: 50%;
    }

    li {
        padding: 20px 10px;

        svg {
            transition: 0.5s all;
        }

        a {
            border-left: 2px solid gray;
            transition: .5s all;
        }

        &:hover {
            a {
                color: #271A62 !important;
                border-left-color: #271A62;
            }

            svg {
                transform: scale(1.2);
            }
        }

        a {
            // color: #000;
            font-weight: 600;
            padding-left: 10px;
            display: inline-flex;
            align-items: center;
            // border-left: 2px solid #271A62;
        }
    }
}


.navbar-toggler {
    border: none !important;
}

.contact-us-page,
.job_modal {

    .form-input,
    .phone {

        input,
        textarea {
            box-shadow: none !important;

            &:focus {
                border-color: #dee2e6;
                border-bottom: 1px solid #5842BC;
                background-color: #E8F0FE;
            }
        }

        .form-select {
            &:focus {
                border-color: #dee2e6;
                border-bottom: 1px solid #5842BC;
            }
        }
    }
}

.payment_system {
    input {
        box-shadow: none !important;

        &:focus {
            border-color: #dee2e6;
            border-bottom: 1px solid #5842BC;
            background-color: #E9EBF8;
        }
    }
}

.contact_back,
.contact_form {

    .form-control {
        box-shadow: none !important;
        padding: 10px 8px;

        &:focus {
            border-color: #dee2e6;
            border-bottom: 1px solid #5842BC;
            background-color: #E8F0FE;
        }
    }

    .react-tel-input .form-control {
        width: 100% !important;
        height: 46px !important;
    }

    .form-select {
        box-shadow: none !important;
        padding: 10px 8px;

        &:focus {
            border-color: #dee2e6;
            border-bottom: 1px solid #5842BC;
        }
    }

    select {
        width: 100%;
        padding: 10px 8px;
        border: 1px solid #dee2e6;

        &:focus {
            border-color: #dee2e6;
            border-bottom: 1px solid #5842BC;
            outline: none;
        }
    }
}

// Dark Header

.bg-black.border-bottoms {
    border-bottom: 1px solid;
}

.dark {
    &.top-header {
        background-color: #000;
        border-bottom: 1px solid rgb(32, 32, 32);
    }

    .head_navbar .submenu li:hover a {
        border-color: #fff;
    }

    .submit_btn {
        color: #000;
    }

    .nav-item {
        .nav-link {
            color: #fff;
        }

        &:hover {
            .nav-link {
                color: #fff !important;
            }
        }
    }

    .contact_form {
        padding: 0 !important;
    }

    .navbar-toggler-icon,
    .btn-close {
        filter: invert(100%);
    }

    .navbar-toggler,
    .btn-close {
        &:focus {
            box-shadow: none !important;
        }
    }

    .mega_div {
        background-color: #000 !important;

        li {
            a {
                color: #fff !important;
                border-left-color: #fff !important;
            }
        }
    }

    .contact_back {
        .btn-close {
            filter: invert(100%);
        }

        label {
            color: #fff !important;
        }
    }
}

.get_quote {
    .modal-dialog {
        @media screen and (min-width:992px) {
            margin-right: 20px !important;
        }
    }

    .modal-body::-webkit-scrollbar {
        width: 0 !important;
    }

    .side_btn {
        position: fixed;
        right: -62px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        z-index: 99;
    }
}

.indicate {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #E6EBF5;
    border-radius: 100%;
    right: 25%;
    animation: wave 1s infinite;
    box-sizing: border-box;
    box-shadow: 0 0 0 0 rgba(230, 235, 245, 0.3);

    @keyframes wave {
        60% {
            box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.05);
        }

        100% {
            box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
        }
    }
}

.indicate-border {
    position: absolute;
    background: transparent;
    right: 25%;
    animation: waveLine 1s infinite;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border: .5px solid #E6EBF5;
    border-radius: 100%;
    transform-origin: center;

    @keyframes waveLine {
        0% {
            transform: scale(1);
        }

        100% {
            height: 8px;
            border: .4px solid #E6EBF5;
            transform: scale(3);
            opacity: 0;
        }
    }
}

.custom-offcanvas {
    height: auto !important;
    max-height: 100vh;
    overflow-y: auto;
}

.mobile_offcanvas {
    .submenu {
        max-height: 0;
        overflow: hidden;
    }

    .submenu.open {
        max-height: 100%;
        animation: effect .2s linear;

        @keyframes effect {
            0% {
                transform: translateY(-3%);
            }

            100% {
                transform: translateY(0);
            }
        }
    }
}