.testimonial_slider,
.digital_provide {

    .owl-nav {
        transition: .5s all;

        @media (max-width:991px) {
            position: unset;
            bottom: 0;
            width: 100%;
        }

        .owl-prev,
        .owl-next {
            width: 41px;
            height: 41px;
            border: 1px solid #DBDBDB !important;
            border-radius: 50% !important;

            &:hover {
                background-color: #271A62 !important;

                i {
                    color: #fff;

                }
            }

            &:not(.disabled) {
                background-color: #271A62 !important;
                color: #fff;
            }
        }
    }
}

.value {
    position: relative;

    &::after {
        content: '';
        width: 45px;
        height: 45px;
        border-left: 2px solid #271A62;
        position: absolute;
        top: -23px;
        right: -23px;
        background-color: #fff;
        transform: rotate(-45deg);
    }
}

.wework {
    .nav-link {
        color: #000 !important;
        font-weight: 600;
    }

    .nav-link.active {
        border-radius: 0;
        background-color: transparent !important;
        border-bottom: 2px solid #5842bc;
    }
}

.ai_width {
    @media screen and (min-width:1024px) {
        width: 41.66% !important;
    }
}

.industry,
.wework,
.values {

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #271A62;
    }

    .owl-theme .owl-dots .owl-dot span {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 1px solid #271A62;
    }
}

.mobile_media {
    .slick-slider .slick-track {
        display: flex;
        align-items: center;
    }
}

.form-floating {
    label {
        z-index: 0 !important;
    }

    .react-tel-input {
        .form-control {
            width: 100% !important;
            height: 58px;
            box-shadow: none !important;
        }
    }
}

.client_box {
    border: 1px solid #E8E8E8;
    border-bottom: 0;
    border-right: 0;
}