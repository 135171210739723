@import 'header/header.scss';
@import 'home/home.scss';
@import 'footer/footer.scss';
@import 'game/game.scss';
@import 'about-us/about.scss';
@import 'common/common.scss';
@import 'erp-crm/erp.scss';
@import 'partner/partner.scss';

.orange_color {
    color: #F17216;
}

.notice {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10000;
    }

    .modal-content {
        background: white;
        margin: 10px;
        padding: 20px;
        border-radius: 5px;
        width: 400px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .modal-footers {
        margin-top: 20px;
    }

    button:disabled {
        background: grey;
        cursor: not-allowed;
        padding: 10px 30px;
        border-radius: 10px;
        margin-top: 10px;
    }

    button {
        background: #271a62;
        cursor: pointer;
        color: #fff;
        padding: 10px 30px;
        border-radius: 10px;
        margin-top: 10px;
    }
}

.slide-track {
    .owl-stage {
        transition-timing-function: linear !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

body>.skiptranslate,
.goog-logo-link,
.gskiptranslate,
.goog-te-gadget span,
.goog-te-banner-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
div#goog-gt- {
    display: none !important;
}

.options_tab {
    top: 0;

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    @media screen and (min-width:992px) {
        top: 0;
    }

    .nav-link.actives {
        background-color: #E9EBF8 !important;
        color: #000 !important;
        border-radius: 9999px;
        transition: .5s;
    }

    .nav-pills::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .rounds {
        border-radius: 50px 0 0 50px;
        margin-left: 20px;

        @media screen and (min-width:768px) {
            border-radius: 50px;
        }
    }

    .rounds1 {
        border-radius: 0 50px 50px 0;
        margin-right: 20px;
    }

    @media screen and (max-width:767px) {
        .container {
            max-width: 100% !important;
            padding: 0;
        }
    }

    .nav-pills {
        @media screen and (min-width:768px) {
            border-radius: 5px;
        }

        @media screen and (max-width:767px) {
            width: 100%;

            .nav-link {
                font-size: 14px;
            }
        }
    }
}

.slick-track {
    display: flex !important;
    align-items: center;
}

// Sign Up

.other_option {
    position: relative;

    &::after {
        content: '';
        width: 60%;
        height: 1px;
        background-color: #4a4a51;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.signup_back {
    background-image: url('../images/industries/indus_back.png'), url('../images/industries/indus_back1.png');
    background-repeat: repeat-x;
    background-position: bottom, top;
}

@media screen and (min-width:992px) {
    .signup_back1 {
        background-image: url('../images/industries/indus_back.png');
        background-repeat: repeat-x;
        background-position: bottom;
    }

}

.sign_up,
.checkout {
    form {
        label {
            margin-bottom: 10px;
        }

        input {
            border: 1px solid #c4c4c8;
            width: 100%;
            border-radius: 5px;
            padding: 10px;
            outline: none;

            &:focus {
                border-color: #271a62 !important;
            }
        }

        .react-tel-input {
            .form-control {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}

.checkout {
    background-image: url('https://blu-smart.com/en-IN/greenBluBack.1978d4ea2771a810a84e.png');
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: contain;
}

.arrow_effect {
    .common_arrow {
        transition: .5s;
    }

    &:hover {
        .common_arrow {
            transform: translateX(5px);
        }
    }
}

.talk_btn {
    &:hover {
        background-color: #271a62;
        color: #fff !important;
    }
}

.hire_option {
    ul {
        li:nth-child(2) {
            display: none;
        }
    }
}


.payment_system {
    input {
        &:focus-visible {
            outline: none;
        }
    }

    .react-tel-input .form-control {
        width: 100% !important;
        height: 46px;
        border-width: 1px;
        border-color: #0000004D;
        box-shadow: none;
    }
}

.service_layer {
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(39, 26, 98, 0.5);
    }
}

.texts-gradient {
    // background: linear-gradient(69.28deg, #2C3A9B 6.84%, #A262E5 60.58%);
    background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.texts-gradient1 {
    background: linear-gradient(84.25deg, #4D3AE4 0%, #A262E5 50%, #F33F60 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 10px;
}

.btn_gradient {
    &:hover {
        .bg-gradients {
            background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
        }

        .texts-gradient {
            color: #fff;
            background: none;
            -webkit-text-fill-color: #fff;
        }
    }
}

.testimonial_slider1 {
    .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
    }

    .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
    }

    .owl-prev,
    .owl-next {
        width: 41px;
        height: 41px;
        border-radius: 50% !important;

        &:hover {
            background-color: #271A62 !important;

            i {
                color: #fff;

            }
        }

        &:not(.disabled) {
            background-color: #271A62 !important;
            color: #fff !important;
        }
    }
}

.switch_tab {
    background: linear-gradient(270deg, #2b47fd, #a067fc 90%);
}

.started_layer {
    z-index: 100;
    background-image: linear-gradient(to bottom, #08080800, #080808 95%);
    height: 15em;
    position: absolute;
    inset: auto 0% -1px;
}

.zunno_faq {

    .accordion-button,
    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
        // background-color: #F9F9F9;
    }

    .accordion-button::after {
        // background-image: url('../images/plus.png');
        width: 20px;
        // transition: .3s;
    }

    .accordion-button:not(.collapsed)::after {
        // background-image: url('../images/minus.png');
        width: 20px;
        transform: none !important;
        // transition: .3s;
    }
}

.back-gradient{
    background-image: url('../images/zunno/gradient.png');
    background-size: cover;
}

.policy_check {
    position: relative;
    &::after {
        content: '';
        background-image: url('https://www.mulesoft.com/sites/default/files/cmm_files/new-graphics-WES_updated121423.svgz');
        width: 26px;
        top: 4px;
        height: 16px;
        position: absolute;
        background-size: 400px;
        background-position: 51px 305px;
        margin-left: 8px;
    }
}