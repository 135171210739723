.dark_footer {
    background-color: #000 !important;
    color: #fff !important;
}

.privacy {
    a {
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom-color: black;
        }
    }
}

.effect {
    display: inline-block !important;
    position: relative;

    @media screen and (min-width:768px) {
        border-bottom: 1px solid transparent !important;

        &:hover {
            border-bottom-color: black !important;
        }
    }
}

.dark-line {
    .effect {
        &:hover {
            border-bottom-color: white !important;
        }
    }

    .privacy {
        a {
            &:hover {
                border-bottom-color: #fff;
            }
        }
    }

    .msme {
        filter: invert(1);
    }
}