// Desclimer
.privacy-wrap {
    p {
        margin-bottom: 40px;
    }

    h4 {
        margin: 40px 0;
        font-size: 22px;

        @media screen and (min-width:768px) {
            font-size: 32px;
        }
    }

    h5 {
        margin-bottom: 20px;
        font-size: 18px;

        @media screen and (min-width:768px) {
            font-size: 24px;
        }
    }
}

// industry_card_info

.industry {
    .ind_cards {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 8px 40.6px 0px #0000001A;

        img {
            transition: .5s;
            transform: translateY(15px) scale(1.2);
        }

        &:hover {
            .ind_des {
                bottom: 0;
            }

            @media screen and (min-width:768px) {
                img {
                    transform: translateY(-25px) scale(1.2);
                }
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background-image: linear-gradient(transparent 70%, rgba(0, 0, 0, 0.8) 90%);
            position: absolute;
            top: 0;
            left: 0;
        }

        .ind_des {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            bottom: -100%;
            left: 0;
            color: #fff;
            z-index: 11;
            padding: 20px;
            transition: .5s;
        }
    }

    .owl-carousel .owl-stage {
        display: flex;
        padding: 30px 0;
    }

    .owl-carousel .owl-stage-outer {
        overflow: hidden;
    }

    .owl-theme .owl-nav {
        position: absolute;
        left: 50%;
        bottom: -40px;
        transform: translateX(-50%);

        button.owl-prev,
        button.owl-next {
            width: 40px;
            height: 40px;
            background-color: #271A62;
            color: #fff;
            border-radius: 50%;

            &:hover {
                color: #fff !important;
            }
        }
    }

    .items {
        display: flex;
        flex: 1 0 auto;
        height: 100%;
        margin: 0 10px;
    }

    .ind_card {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}

.industry_card_info {
    .industry_inner_info {
        h4 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            transition: .3s;
        }

        p {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(50%);
            opacity: 0;
            transition: .3s;
            padding: 0 12px;
        }
    }

    &:hover {
        .industry_inner_info {
            p {
                opacity: 1;
                transform: translateY(-20%);
            }

            h4 {
                transform: translate(-50%, -100%);
            }
        }
    }
}


// contact us
.contact-us-page {
    .contact_back_img {
        background-image: url('../../images/industries/indus_back.png');
        background-repeat: repeat-x;
        background-position: bottom;
    }

    .form-input {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input,
        textarea {
            padding: 10px 8px;
            display: block;
            width: 100%;
            outline: 0;
            border: 1px solid #e2e2e2;
            border-radius: 5px;
        }
    }
}

.faq_card {
    .box {
        background-color: #fff;
        box-shadow: 0px 0px 20px 4px rgba(207, 207, 207, 0.2);
        border: 1px solid #f1f1f1;
        text-align: center;
        padding: 20px 0;
        border-radius: 10px;

        a {
            color: #000;
        }
    }
}

.contact_part {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background: #fafafa;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
    }

    .react-tel-input {
        .form-control {
            width: 100% !important;
            height: 46px;
            box-shadow: none !important;
        }
    }
}

.help_form {
    .form-input {
        .form-select {
            box-shadow: none;
            padding: 10px 8px;
        }

        input,
        textarea {
            border-radius: 0.375rem;
        }

        input::placeholder,
        textarea::placeholder {
            color: #000 !important;
        }
    }
}

.g-recaptcha {
    width: 100%;
}

.g-recaptcha>div>div {
    width: 100% !important;
}

.g-recaptcha iframe {
    height: auto !important;
    max-width: 100%;
}

.job_modal {
    .form-input {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input,
        .form-select {
            padding: 10px 8px;
            display: block;
            width: 100%;
            outline: 0;
            border: 1px solid #e2e2e2;
            border-radius: 0.375rem;
            box-shadow: none;

            &::placeholder {
                color: #000;
            }
        }
    }

    .react-tel-input {
        .form-control {
            width: 100% !important;
            height: 46px;
            box-shadow: none !important;
        }
    }
}

.swiper-item {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.erp_slider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
}

.erp_slider .swiper-slide .item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.erp_slider .swiper-slide .item>div {
    height: 100%;
}

.erp_slider .swiper-wrapper {
    padding-bottom: 50px;

    @media screen and (max-width:767px) {
        padding-bottom: 10px;
    }
}

.swiper-pagination-bullet-active {
    background-color: #271A62 !important;
}