.shadows {
    box-shadow: 0px 8px 40.6px 0px #0000001A;
}

/* ========== Scroll To Top ============== */
#scroll_top {
    position: fixed;
    bottom: 130px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    border-radius: 50%;
    cursor: pointer;
    transition: 1s;
    font-size: 20px;
}

@media (min-width:1024px) {
    .container {
        max-width: 72rem !important;
        padding: 0 32px !important;
    }
}

ul {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

a {
    text-decoration: none !important;
}

.future_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5rem;
    color: #14142b;
    text-transform: none !important;
}

.backgroudSlider {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    height: 400px !important;
}

.box-trends {
    box-shadow: 0 0 2px rgba(0, 0, 0, .4);
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    padding: 10px;
    transition: .5s all;
}

.our_service .nav-pills .nav-link.active {
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 50px;
    transition: 1s all;
}

.our_service .nav-pills .nav-link {
    color: #fff !important;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: start;
    border-radius: 50px;
    white-space: nowrap;
}

.circle .nav::-webkit-scrollbar {
    width: 0px;
}

.our_service .nav-pills {
    padding: 30px 12px 0px;
    margin-bottom: 10px;
}

@media screen and (min-width:1200px) {
    .our_service .nav-pills {
        padding: 50px 25px 40px;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .circle .nav {
        flex-wrap: wrap;
    }

    .our_service .nav-pills .nav-link {
        font-size: 20px;
        width: 100%;
        padding: 10px 20px;
    }
}

.box-trends:hover {
    background-color: #E9EBF8;
    transform: scale(1.03);
}

.main-imagediv {
    overflow: hidden;
    border-radius: 10px;
}

.main-imagediv img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.owl-next:hover,
.owl-prev:hover {
    color: black !important;
}

.thumb .active img {
    filter: grayscale(0);
}

.contact_form .form-check-input:checked {
    background-color: #271A62 !important;
    box-shadow: none !important;
    border-color: #271A62 !important;
}

.contact_form .form-check-input:focus {
    box-shadow: none !important;
}

.contact_form .form-check-input {
    border-radius: 50% !important;
}

.contact_form input,
.contact_form select,
.contact_form textarea {
    border-radius: 5px !important;
}

.contact_back {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.blog_banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../public/assets/images/industries/blog_banner.png");
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 60vh;
    display: flex;
    align-items: center;
}

.bar-primary {
    width: 4px;
    height: 1em;
    content: "";
    margin-right: 1rem;
    display: inline-flex;
    background-color: #271A62;
}

.interstitial-wrapper {
    position: relative;
    width: 600px;
    height: 150px;
    margin: 0 auto;
}

#offline-canvas {
    display: block;
    background: white;
}