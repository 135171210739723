.partner_back {
    background-image: radial-gradient(ellipse 75% 50%, rgba(89, 124, 255, 0.2), transparent);
    background-repeat: no-repeat;
    background-position: center right;
}

.partner_faq {
    .accordion {
        --bs-accordion-border-radius: 0 !important;
    }

    .accordion-button,
    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 600;

        @media screen and (min-width:576px) {
            font-size: 18px;
        }
    }

    .accordion-button {
        padding: 20px;
    }

    .accordion-button:not(.collapsed) {
        color: #000 !important;
    }

    .accordion-item {
        border-width: 0 0 1px 0 !important;
        border-bottom: 1px solid lightgray !important;
        border-radius: 0;
        background-color: transparent !important;
    }
}

.partner_join {
    .owl-theme .owl-dots .owl-dot span {
        background-color: transparent;
        border: 1px solid #271A62;
    }

    .owl-theme .owl-dots .owl-dot.active span {
        background-color: #271A62 !important;
    }
}

// Payment System

@font-face {
    font-display: swap;
    font-family: 'Ribeye';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/ribeye-v25-latin-regular.woff2') format('woff2');
}

.pay_text {
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #5F259F;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.payment {
    input::placeholder {
        font-size: 14px;
    }
}

@media (max-width:991px) {
    .arrow {
        display: none;
    }
}