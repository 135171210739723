// @import url(https://db.onlinewebfonts.com/c/a7fcb1becc12274d5b761b1d895f8aa9?family=Minecraft+Ten);
@font-face {
    font-family: "Minecraft Ten";
    src: url("../../fonts/Minecraft\ Ten.eot");
    src: url("../../fonts/Minecraft\ Ten.eot?#iefix")format("embedded-opentype"),
        url("../../fonts/Minecraft\ Ten.woff2")format("woff2"),
        url("../../fonts/Minecraft\ Ten.woff")format("woff"),
        url("../../fonts/Minecraft\ Ten.ttf")format("truetype"),
        url("../../fonts/Minecraft\ Ten.svg#Minecraft Ten")format("svg");
}

.game_banner {
    background-image: url('../../images/game/game_banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 100px 0;

    p {
        font-weight: 300;
        color: #fff;
    }
}

.game_title {
    font-family: "Minecraft Ten";
    color: #fff;
    text-align: center;
    font-size: 56px;
    margin-bottom: 30px;

    @media screen and (max-width:991px) {
        font-size: 40px;
    }

    @media screen and (max-width:767px) {
        font-size: 30px;
    }
}

.game_card {
    .card {
        border-radius: 0 !important;
        overflow: hidden;
        background-color: transparent !important;
        height: 440px !important;

        &:hover {
            .card-img-overlay {
                display: none;
            }

            .overlay {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }

    .card-img-overlay {
        height: 30%;
        top: auto !important;
        bottom: 0 !important;
        border-radius: 0 !important;
        transition: .3s all;

        @media screen and (max-width: 992px) {
            height: 25%;
        }
    }
}

.process {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../images/game/process-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 100px 0;

    .process_icon {
        width: 120px;
        height: 120px;
        border: 2px solid #fff;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 30px;
        transition: .5s all;
    }

    .process_card {
        img {
            transition: .7s all;
        }

        &:hover {
            .process_icon {
                border-color: #3C8527;
            }

            img {
                transform: rotateY(360deg);
            }
        }
    }
}

.choose {
    .process_card {
        box-shadow: 0 0 2px #fff !important;
    }
}

.contact_page {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../images/game/game_contact.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}