.ErpProvide {
    .accordion-item {
        @media screen and (min-width:992px) {
            margin-bottom: 15px;
            border: 1px solid #E4E4E4 !important;
            border-radius: 0.375rem !important;
        }

        @media screen and (max-width:992px) {
            padding: 16px 0;

            .accordion-body {
                padding: 10px !important;
            }
        }

        border-width: 0 0 1px 0 !important;
        border-bottom: 1px solid #E4E4E4 !important;
        border-radius: 0 !important;
    }

    .accordion-button {
        background-color: transparent !important;
        box-shadow: none !important;

        @media screen and (max-width:1023px) {
            padding: 10px;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    .accordion-button:not(.collapsed) {
        color: #271A62 !important;
    }
}

.erp_card {
    text-align: center;
}

.erp_slider {
    @media screen and (min-width:768px) {

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 10%;
            height: 100%;
            z-index: 2;
            left: -10px;
            background: -webkit-gradient(linear, right top, left top, from(transparent), to(white));
            background: linear-gradient(to left, transparent, white);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 10%;
            height: 100%;
            z-index: 2;
            right: -10px;
            background: -webkit-gradient(linear, right top, left top, from(transparent), to(white));
            background: linear-gradient(to left, white, transparent);
        }
    }
}

@media screen and (min-width:992px) {
    .space {
        .modal-dialog {
            margin-right: 50px !important;
            margin-left: auto !important;
        }
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
    width: 41px !important;
    height: 41px !important;
    border: 1px solid #271A62 !important;
    border-radius: 50% !important;
    color: #fff !important;
    background-color: #271A62;

    &::after {
        font-size: 15px !important;
    }
}

.erp_slider {
    .swiper-slide {
        height: auto !important;

        @media screen and (max-width:767px) {
            margin-bottom: 50px;
        }

        .item {
            height: 100%;
        }
    }

    @media screen and (max-width:767px) {
        .swiper-button-prev {
            bottom: 0% !important;
            top: auto !important;
            left: 40% !important;
        }

        .swiper-button-next {
            bottom: 0% !important;
            top: auto !important;
            right: 40% !important;
        }
    }

    @media screen and (max-width:575px) {
        .swiper-button-prev {
            left: 36% !important;
        }

        .swiper-button-next {
            right: 36% !important;
        }
    }
}

.contact_back {
    background-image: linear-gradient(95.71deg, #000A19 -58.24%, #271A62 157.87%);
    padding: 70px 0;
}

.ErpProvide {
    .box {
        padding: 0px 12px;

        @media screen and (min-width:992px) {
            border: 3px solid rgba(0, 0, 0, 0.5);
            box-shadow: 5px 5px 0px #271A62;
            padding: 50px 16px;
        }
    }
}

.slide-track {
    transition-timing-function: linear !important;
}